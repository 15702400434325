export const mainHeader = 'Ścianka reklamowa prosta';
export const pageDescription = 'Proponujemy Państwu profesjonalne i wytrzymałe ścianki reklamowe tekstylne. Sprawdzą się nie tylko na targach i w galeriach, a także jako tło do zdjęć.';

export const wallInfo = {
  icon: true,
  priceList: true,
  leftColumn: [
    {
      article: `**${mainHeader} <span class="orange">czas realizacji</span>**
      \n ok. 3 dni roboczych`,
    },
    {
      article: `**${mainHeader} <span class="orange">koszt wysyłki</span>**
      \n 25,00 zł/netto`,
    },
    {
      article: `\n **${mainHeader} <span class="orange">wymiar</span>**
      \n Dostępne wymiary ścianki tekstylnej prostej (wys. x szer.):
      \n * ścianka prosta 2,4 x 2 m
      \n * ścianka prosta 2,4 x 2,4 m
      \n * ścianka prosta 2,4 x 3 m
      \n * ścianka prosta 2,4 x 4 m
      \n * ścianka prosta 2,4 x 4 m
      \n * ścianka prosta 2,4 x 6 m
      `,
    },
  ],
  rightColumn: [
    {
      article: `**${mainHeader} <span class="orange">opis</span>**
      \n Ścianka tekstylna o prostym kształcie z zaokrąglonymi górnymi rogami wykonana jest z solidnego i wytrzymałego stelaża aluminiowego z ramą o średnicy 30mm. Niezwykle szybki i prosty montaż wszystkich elementów, dodatkowo oznaczonych, montowanych na “click” bez użycia narzędzi.
      Grafika nadrukowana jedno lub dwustronnie na lekkiej i trwałej tkaninie materiałowej, którą można po zabrudzeniu wyprać, naciąganej na konstrukcję. Cały system z grafiką umożliwia stworzenie zdumiewającej, wielkowymiarowej ekspozycji graficznej, a Klienci doceniają ją za profesjonalny
      wygląd, wygodę użytkowania oraz doskonałą jakość nadruku. Nazywana również ścianką materiałową lub potocznie jako ścianka wystawiennicza, ścianka konferencyjna, ścianka targowa, ścianka prezentacyjna lub prasowa doskonale sprawdzi się dla wystawców targowych,
      sklepów, stoisk i innych przedsiębiorców, którzy chcą zaprezentować swoje usługi i produkty w atrakcyjny sposób przy okazji targów, eventów czy innych różnego rodzaju wydarzeń. Ścianka jest wielokrotnego użytku z możliwość dodrukowania nowej grafiki, dostępna w wielu rozmiarach w zestawie z materiałową torbą transportową.
      \n Materiał: Display Stretch 260 g/m², atest niepalności B1.
      \n Druk wykonywany jest metodą sublimacji z wykorzystaniem bezwonnych ekologicznych tuszów. Zaletą tej metody nadruku jest wysoka jakość nadruku oraz nasycenie barw. Dodatkowo wydruk odporny jest na zarysowania oraz z powodzeniem można go prać i prasować.
      `,
    },
    {
      article: `**${mainHeader} <span class="orange">zalety i cechy</span>**
      \n * szybki i prosty montaż,
      \n * trwała konstrukcja,
      \n * nadruk jedno lub dwustronny,
      \n * możliwość wyprania lub wymienienia grafiki,
      \n * wygodna w transporcie,
      \n * doskonała jakość wydruku,
      \n * profesjonalny wygląd
      `,
    },
    {
      article: `**${mainHeader} <span class="orange">zastosowanie</span>**
      \n * na targach,
      \n * na eventach okolicznościowy,
      \n * w galeriach i sklepach,
      \n * w biurze,
      \n * w studiach telewizyjnych,
      \n * nagrań filmów na Youtube i zdjęć na Instagram,
      \n * jako tło do zdjęć.
      `,
    },
    {
      article: `**${mainHeader} <span class="orange">projekt graficzny</span>**
      \n Jeśli potrzebują Państwo wykonać projekt graficzny zachęcamy do skorzystania z naszych usług.
      `,
    },
  ],
};
